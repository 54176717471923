import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Loader } from '..';
import { ApiContext } from '../../providers';
import { oktaRoles } from '../../utils';

const { useApi } = ApiContext;

function RegionSelect({ id }) {
  const { authState } = useOktaAuth();
  const isAdmin = authState.isRoleAllowed(oktaRoles.ADMIN);
  const { request } = useApi();
  const [regionChecked, setRegionChecked] = useState({
    NA: true,
    EU: false,
    FE: true,
  });
  const [countryChecked, setCountryChecked] = useState([]);
  const [regionData, setRegionData] = useState(undefined);

  useEffect(() => {
    request('regions').then((data) => {
      console.log(data);
      setRegionData(data);
    });
  }, []);

  const handleRegionCheck = () => {
    // const { value, checked } = e.target;
    /* do something */
    setRegionChecked({
      ...regionChecked,
    });
  };

  const handleCountryCheck = (e) => {
    const { value, checked } = e.target;

    let newCheckList;
    if (!checked) {
      newCheckList = countryChecked.filter((i) => i !== value);
    } else {
      newCheckList = [...countryChecked, value];
    }

    setCountryChecked(newCheckList);
  };

  if (!regionData) {
    return <Loader large />;
  }

  return (
    <div id={id} className="d-flex">
      <div className="my-3 mx-5">
        <div className="form-check">
          <input
            id="region-na"
            type="checkbox"
            className="form-check-input"
            value="NA"
            disabled={!isAdmin}
            checked={regionChecked.NA}
            onChange={handleRegionCheck}
          />
          <label htmlFor="region-na" className="form-check-label">
            NA: North America
          </label>
        </div>
        {regionData.NA &&
          regionData.NA.map((reg) => (
            <div key={reg.marketplace} className="form-check ms-3">
              <input
                id={`region-na-${reg.countryCode}`}
                type="checkbox"
                className="form-check-input"
                value={reg.marketplace}
                disabled={!isAdmin}
                checked={countryChecked.includes(reg.marketplace)}
                onChange={handleCountryCheck}
              />
              <label
                htmlFor={`region-na-${reg.countryCode}`}
                className="form-check-label"
              >
                {reg.name}
              </label>
            </div>
          ))}
      </div>
      <div className="my-3 mx-5">
        <div className="form-check">
          <input
            id="region-eu"
            type="checkbox"
            className="form-check-input"
            value="EU"
            disabled={!isAdmin}
            checked={regionChecked.EU}
            onChange={handleRegionCheck}
          />
          <label htmlFor="region-eu" className="form-check-label">
            EU: European Region
          </label>
        </div>
        {regionData.EU &&
          regionData.EU.map((reg) => (
            <div key={reg.marketplace} className="form-check ms-3">
              <input
                id={`region-eu-${reg.countryCode}`}
                type="checkbox"
                className="form-check-input"
                value={reg.marketplace}
                disabled={!isAdmin}
                checked={countryChecked.includes(reg.marketplace)}
                onChange={handleCountryCheck}
              />
              <label
                htmlFor={`region-eu-${reg.countryCode}`}
                className="form-check-label"
              >
                {reg.name}
              </label>
            </div>
          ))}
      </div>
      <div className="my-3 mx-5">
        <div className="form-check">
          <input
            id="region-fe"
            type="checkbox"
            className="form-check-input"
            value="FA"
            disabled={!isAdmin}
            checked={regionChecked.FE}
            onChange={handleRegionCheck}
          />
          <label htmlFor="region-fe" className="form-check-label">
            FE: Far East
          </label>
        </div>
        {regionData.FE &&
          regionData.FE.map((reg) => (
            <div key={reg.marketplace} className="form-check ms-3">
              <input
                id={`region-fe-${reg.countryCode}`}
                type="checkbox"
                className="form-check-input"
                value={reg.marketplace}
                disabled={!isAdmin}
                checked={countryChecked.includes(reg.marketplace)}
                onChange={handleCountryCheck}
              />
              <label
                htmlFor={`region-fe-${reg.countryCode}`}
                className="form-check-label"
              >
                {reg.name}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RegionSelect;
